import React from 'react';
import { Link, NavLink } from 'react-router-dom'

import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import WarehouseService from '../../components/Layout/WarehouseService';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { api } from '../../components/config/api';
import strings from '../../components/Language';

class InstallmentScreen extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			lang: localStorage.getItem("language"),
			best_seller_data: [],
		}
	}

	async componentDidMount() {
		window.scrollTo(0, 0)
		this.getData();
	}

	getData() {
		var self = this;
		axios({
			method: 'GET',
			url: api.best_seller,
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				'Content-Type': 'application/json',
				'Accept-Language': self.state.lang,
			}
		}).then(function (ress) {

			if (ress.data && ress.data.error === 200) {
				self.setState({ best_seller_data: (ress.data && ress.data.data) ? ress.data.data : [``] });
			}
		}).catch(function (e) {
			console.log(e);
		});
	};

	render() {
		return (
			<>
				<Helmet><title>{"Device Warehouse | " + strings["Installment"]}</title></Helmet>
				<Header />

				<div className="container">
					<div className="interest">
						<div className="row align-items-center">
							<div className="col-12">
								<h2>{strings["Installment"]}</h2>
							</div>
							<div className="col">
								<div className="mb-3 position-relative">
									<img src="assets/images/ist.jpg" className="d-block w-100" alt="" />
									<div className="overlay">
										<h2>{strings["Easy installment without card"]}</h2>
										<p>{strings["Low down payment, comfortable installments"]}</p>
									</div>
								</div>
							</div>
							<div className="col text-start">
								<ul>
									<li>{strings["- Any occupation can pay by installment."]}</li>
									<li>{strings["- Easy installments, quick results, no need to have a guarantor"]}</li>
									<li>{strings["- Place a down payment right away get the device to use"]}</li>
									<li>{strings["- Guaranteed grade A machine with 1 year in house warranty"]}</li>
									<li>{strings["- iPhone, easy installment, use only one ID card"]}</li>
									<li>{strings["- Book a appointment and send the phone to your home"]}</li>
								</ul>

								<div className="col-12">
									<Link to={`/product-register`} className="btn btn-primary">{strings["Apply now"]}</Link>
								</div>
							</div>
						</div>
					</div>
				</div>

				<section className="steps">
					<div className="container">
						<div className="row">
							<div className="col-12">
								<h2>{strings["Get Your Device In These 3 Simple Steps!"]}</h2>
							</div>
							<div className="col-md-9 mx-auto">
								<div className="row">
									<div className="col-4">
										<div className="box">
											<picture><img src="assets/images/icn_05.png" alt="" /></picture>
											<p dangerouslySetInnerHTML={{ __html: strings["1. Fill Up Your Information On Our Website."] }} />
										</div>
									</div>
									<div className="col-4">
										<div className="box">
											<picture><img src="assets/images/icn_06.png" alt="" /></picture>
											<p dangerouslySetInnerHTML={{ __html: strings["2. Get Approved Within A Day!"] }} />
										</div>
									</div>
									<div className="col-4">
										<div className="box">
											<picture><img src="assets/images/icn_07.png" alt="" /></picture>
											<p dangerouslySetInnerHTML={{ __html: strings["3. We Will Arrange The Delivery To Your Address!"] }} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="seller">
					<div className="container">
						<div className="row g-4">
							<div className="col-12">
								<h2>{strings["Our Best Sellers Right Now!"]}</h2>
							</div>
							{this.state.best_seller_data.map((item, i) =>
								<div key={i + 100} className="col-6 col-md-3">
									<div className="display">
										<span className="badge">{strings["Hot"]}</span>
										<picture className='product_img insta_list'>
											<img src={item.image_url ? item.image_url : "assets/images/iphone-13.png"} alt="" />
										</picture>
										<h4 style={{ marginTop: 5 }}>{item.en_name}</h4>
										<div dangerouslySetInnerHTML={{ __html: item.en_detail.substring(0, 20) }} />
										<Link to={`/product/${item.id}`} className="btn"> {strings["Choose a pattern"]}<img src="assets/images/arw_rud.svg" className="ms-2" alt="" />
										</Link>
									</div>
								</div>
							)}
							<div className="col-12">
								<NavLink to={"/installment-list"} href="installments-list.html" className="btn btn-primary">
									{strings["More"]}<i className="fa fa-angle-right"></i>
								</NavLink>
							</div>
						</div>
					</div>
				</section>

				<WarehouseService />
				<Footer />
			</>
		);
	}
}

export default InstallmentScreen;
