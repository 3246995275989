import React from 'react';
import { Link } from 'react-router-dom'

import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import WarehouseService from '../../components/Layout/WarehouseService';
import { Helmet } from 'react-helmet';
import strings from '../../components/Language';

class ContactUsScreen extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		}
	}

	async componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		return (
			<>
				<Helmet><title>{"Device Warehouse | " + strings["Locate Us"]}</title></Helmet>
				<Header />

				<div className="container">
					<div className="mt-5">
						<div className="col-12 text-center mb-3">
							<h2>{strings["Locate Us"]}</h2>
						</div>
						<div className="row">
							<div className="col-md-6 col-sm-12 mb-3">
								<img src="assets/images/rk.jpg" alt="" />
								<p>
									<div style={{ marginBottom: 10 }}>
										{strings["Kheha Romklao Branch, Bangkok"]}<br />
										<a href="https://www.google.com/maps/search/?api=1&query=13.7910131,100.5998317">View on Google Map</a>
									</div>
								</p>
							</div>
							<div className="col-md-6 col-sm-12 mb-3">
								<img src="assets/images/cnx.jpg" alt="" />
								<p>
									<div>
										{strings["Chiang Mai Branch"]}<br />
										<a href="https://www.google.com/maps/place/18°48'05.4%22N+98°58'42.6%22E/@18.8015024,98.9759324,17z/data=!3m1!4b1!4m4!3m3!8m2!3d18.8015024!4d98.9785073?entry=ttu">
											View on Google Map
										</a>
									</div>
								</p>
							</div>
							<div className="col-md-6 col-sm-12 mb-3">
								<img src="assets/images/thonglor.jpg" alt="" />
								<p className='mt-3'>
									<div>
										{strings["283/39,41 อาคารโฮมเพลสออฟฟิศบิลดิ้ง ชั้นที่ 8 (Mint) ซอยสุขุมวิท 55 (ทองหล่อ13) แขวงคลองตันเหนือ เขตวัฒนา กรุงเทพมหานคร 1011"]}<br />
										<a href="https://maps.app.goo.gl/y6b6jCfzf2Y1xwGHA">
											View on Google Map
										</a>
									</div>
								</p>
							</div>
						</div>
						<div className="col-md-6 col-sm-12 mb-3 ps">
							<h1>{strings["Our Channels"]}</h1>
							<p>
								{strings["Line OA"]}:  <a href='https://line.me/R/ti/p/@490zukar'>@DeviceWH</a><br />
								{strings["Facebook Page"]} : <a href='https://www.facebook.com/people/Device-Warehouse/100092512337529/?mibextid=LQQJ4d'>Device Warehouse</a>
							</p>
						</div>
					</div>
				</div>

				<WarehouseService />
				<Footer />
			</>
		);
	}
}

export default ContactUsScreen;
