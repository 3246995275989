import React from 'react';
import { Link, NavLink } from 'react-router-dom'

import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import WarehouseService from '../../components/Layout/WarehouseService';
import { Helmet } from 'react-helmet';
import strings from '../../components/Language';

class ExchangeForMoneyScreen extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			params_id: ""
		}
	}

	async componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		return (
			<>
				<Helmet><title>{"Device Warehouse | " + strings["iPhone Money Exchange"]}</title></Helmet>
				<Header />

				<div className="container">
					<div className="interest">
						<div className="row align-items-center">
							<div className="col-12">
								<h2>{strings["iPhone Money Exchange"]}</h2>
							</div>
							<div className="col">
								<div className="mb-3 position-relative">
									<img src="assets/images/ex_mny.jpg" className="d-block w-100" alt="" />
									<div className="overlay">
										<h2>{strings["iPhone Money Exchange"]}</h2>
										<p>{strings["Earn good money. Have an iPhone."]}</p>
									</div>
								</div>
							</div>
							<div className="col text-start disc">
								<p dangerouslySetInnerHTML={{ __html: strings["iphone_money_exchange_list"] }} />
							</div>
							<div className="col-12 disc">
								<p className="m-0" dangerouslySetInnerHTML={{ __html: strings["iphone_money_exchange_list_"] }} />
							</div>
						</div>
					</div>
				</div>

				<section className="steps">
					<div className="container">
						<div className="row">
							<div className="col-12">
								<h2>{strings["Get Your Device In These 3 Simple Steps!"]}</h2>
							</div>
							<div className="col-md-9 mx-auto">
								<div className="row">
									<div className="col-4">
										<div className="box">
											<picture><img src="assets/images/icn_05.png" alt="" /></picture>
											<p dangerouslySetInnerHTML={{ __html: strings["1. Fill Up Your Information On Our Website."] }} />
										</div>
									</div>
									<div className="col-4">
										<div className="box">
											<picture><img src="assets/images/icn_06.png" alt="" /></picture>
											<p dangerouslySetInnerHTML={{ __html: strings["2. Get Approved Within A Day!"] }} />
										</div>
									</div>
									<div className="col-4">
										<div className="box">
											<picture><img src="assets/images/icn_07.png" alt="" /></picture>
											<p dangerouslySetInnerHTML={{ __html: strings["3. We Will Arrange The Delivery To Your Address!"] }} />
										</div>
									</div>
								</div>
							</div>
							<div className="col-12 mt-3">
								{this.state.params_id ?
									<Link to={`/product-register/${this.state.params_id}`} type="submit" className="btn btn-primary" style={{ width: "auto" }}>
										{strings["Want to spend money,  click now"]} <i className="fa fa-angle-right" style={{ marginLeft: 10 }}></i>
									</Link>
									:
									<Link to={`/product-register`} type="submit" className="btn btn-primary" style={{ width: "auto" }}>
										{strings["Want to spend money,  click now"]} <i className="fa fa-angle-right" style={{ marginLeft: 10 }}></i>
									</Link>
								}
							</div>
						</div>
					</div>
				</section>

				<WarehouseService />
				<Footer />
			</>
		);
	}
}

export default ExchangeForMoneyScreen;
