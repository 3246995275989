import React from 'react';
import { Link, NavLink } from 'react-router-dom'
import Slider from "react-slick";

import Header from "./../../components/Layout/Header";
import Footer from "./../../components/Layout/Footer";
import { Helmet } from 'react-helmet';
import { api } from '../../components/config/api';
import axios from 'axios';
import strings from '../../components/Language';

class HomeScreen extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			lang: localStorage.getItem("language"),
			best_seller_data: [],
		}
	}

	async componentDidMount() {
		window.scrollTo(0, 0)
		this.getData();
	}

	getData() {
		var self = this;
		axios({
			method: 'GET',
			url: api.best_seller,
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				'Content-Type': 'application/json',
				'Accept-Language': self.state.lang,
			}
		}).then(function (ress) {
			if (ress.data && ress.data.error === 200) {
				self.setState({ best_seller_data: (ress.data && ress.data.data) ? ress.data.data : [``] });
			}
		}).catch(function (e) {
			console.log(e);
		});
	};

	render() {
		var window_innerHeight = window.innerWidth;
		var slidesToShow = (this.state.best_seller_data.length < 4) ? this.state.best_seller_data.length : 4;
		if (window_innerHeight < 431) {
			slidesToShow = 1;
		} else if (window_innerHeight < 769) {
			slidesToShow = (this.state.best_seller_data.length < 2) ? this.state.best_seller_data.length : 2;
		} else if (window_innerHeight < 993) {
			slidesToShow = (this.state.best_seller_data.length < 3) ? this.state.best_seller_data.length : 3;
		}
		const settings = {
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: slidesToShow,
			slidesToScroll: 1
		};

		return (
			<>
				<Helmet><title>{"Device Warehouse | " + strings["Home"]}</title></Helmet>
				<Header />


				<div id="carouselHero" className="carousel slide" data-bs-ride="carousel">
					<div className="carousel-indicators">
						<button type="button" data-bs-target="#carouselHero" data-bs-slide-to="0" className="" aria-label="Slide 1"></button>
						<button type="button" data-bs-target="#carouselHero" data-bs-slide-to="1" aria-label="Slide 2" className="active"
							aria-current="true"></button>
						<button type="button" data-bs-target="#carouselHero" data-bs-slide-to="2" aria-label="Slide 3" className=""></button>
					</div>
					<div className="carousel-inner">
						<div className="carousel-item">
							<img src={"assets/images/banner_" + this.state.lang + "_01.png"} className="d-block w-100" alt="" />
							<div className="carousel-caption">
								<Link to={`/product-register`} className="btn btn-light">{strings["Apply now"]} <i className="fa fa-angle-right"></i> </Link>
							</div>
						</div>
						<div className="carousel-item active">
							<img src={"assets/images/banner_" + this.state.lang + "_02.png"} className="d-block w-100" alt="" />
							<div className="carousel-caption">
								<Link to={`/product-register`} className="btn btn-light"> {strings["Apply now"]} <i className="fa fa-angle-right"></i> </Link>
							</div>
						</div>
						<div className="carousel-item">
							<img src={"assets/images/banner_" + this.state.lang + "_03.png"} className="d-block w-100" alt="" />
							<div className="carousel-caption">
								<Link to={`/product-register`} className="btn btn-light">{strings["Apply now"]}<i className="fa fa-angle-right"></i></Link>
							</div>
						</div>
					</div>
				</div>
				<div className="container">
					<div className="interest">
						<div className="row">
							<div className="col-12 mb-4">
								<h2>{strings["What We Offer At Device Warehouse"]}</h2>
							</div>
							<div className="col">
								{/* <h3>{strings["Which machine are you aiming for? Check it out!"]}</h3> */}
								<div className="mb-3 position-relative">
									<Link to={`/installment`}>
										<img src={"assets/images/dv_banner_01_" + this.state.lang + ".png"} className="d-block w-100" alt="" />
										<div className="overlay">

										</div>
									</Link>
								</div>
							</div>
							<div className="col">
								{/* <h3>{strings["Hot money iPhone in your hand can help!"]}</h3> */}
								<div className="mb-3 position-relative">
									<Link to={`/exchange-for-money`}>
										<img src={"assets/images/dv_banner_02_" + this.state.lang + ".png"} className="d-block w-100" alt="" />
										<div className="overlay">

										</div>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
				<section className="best">
					<div className="container">
						<div className="row g-4">
							<div className="col-12">
								<h2>{strings["Our Best Sellers Right Now!"]}</h2>
							</div>
							<div className="tns-outer" id="tns1-ow">
								<div className="tns-controls" aria-label="Carousel Navigation" tabIndex="0" style={{ display: "none" }}>
									<button data-controls="prev" tabIndex="-1" aria-controls="tns1">{strings["Prev"]}</button>
									<button data-controls="next" tabIndex="-1" aria-controls="tns1">{strings["Next"]}</button>
								</div>
								<div className="tns-liveregion tns-visually-hidden" aria-live="polite" aria-atomic="true">
									{strings["slide"]} <span className="current">1 to 4</span> of 4
								</div>

								<Slider {...settings}>
									{this.state.best_seller_data.map((item, i) =>
										<div key={i + 100}>
											<div style={{ padding: 10 }} className="tns-item tns-slide-active" id="tns1-item1">
												<div className="display">
													<span className="badge">{strings["Hot"]}</span>
													<picture className='product_img'>
														<img src={item.image_url ? item.image_url : "assets/images/iphone-13.png"} alt="" />
													</picture>
													<h4 style={{ marginTop: 5 }}>{item.en_name}</h4>
													<div dangerouslySetInnerHTML={{ __html: item.en_detail.substring(0, 20) }} />
													<Link to={`/product/${item.id}`} className="btn" style={{ display: "flex", justifyContent: "center" }}> {strings["Choose a pattern"]} <img src="assets/images/arw_rud.svg" className="ms-2" alt="" />
													</Link>
												</div>
											</div>
										</div>
									)}
								</Slider>
							</div>
						</div>
					</div>
				</section>
				<div className="container">
					<div className="easy">
						<div className="row">
							<div className="col-12">
								<h2>{strings["Enjoy a hassle-free, convenient installment process when you shop at Device Warehouse"]}</h2>
							</div>
							<div className="col">
								<figure>
									<img src="assets/images/icn_01.png" alt="" />
								</figure>
								<h3> {strings["Pay In Installments"]}<br /> {strings["Without A Credit Card"]} </h3>
							</div>
							<div className="col">
								<figure>
									<img src="assets/images/icn_02.png" alt="" />
								</figure>
								<h3> {strings["Flexible Plans"]}<br /> {strings["No Guarantor Needed"]} </h3>
							</div>
							<div className="col">
								<figure>
									<img src="assets/images/icn_03.png" alt="" />
								</figure>
								<h3> {strings["Receive your device"]}<br /> {strings["immediately after"]}<br /> {strings["down payment"]} </h3>
							</div>
							<div className="col">
								<figure>
									<img src="assets/images/icn_04.png" alt="" />
								</figure>
								<h3> {strings["Installment payments are"]}<br /> {strings["available for any profession"]} </h3>
							</div>
						</div>
					</div>
				</div>
				<section className="install">
					<div className="container">
						<div className="row align-items-center">
							<div className="col order-md-2">
								<h2>{strings["Why Choose Device Warehouse?"]}</h2>
								<ul>
									<li>{strings["- High Customer Satisfaction"]}</li>
									<li>{strings["- Genuine, Top-Quality Devices"]}</li>
									<li>{strings["- Transparent Pricing"]}</li>
									<li>{strings["- Affordable For All Occupations"]}</li>
								</ul>
							</div>
							<div className="col-md-5 order-md-1">
								<picture><img src="assets/images/phone_01.png" alt="" /></picture>
							</div>
						</div>
					</div>
				</section>

				<Footer />
			</>
		);
	}
}

export default HomeScreen;
