import React, { useState } from 'react'

import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'

//  CSS
import './assets/css/custom.css'

//  Components
import NotFound from './components/NotFound'
import FaqScreen from "./pages/Faq";
import HomeScreen from "./pages/Home";
import AboutUsScreen from "./pages/AboutUs";
import ContactUsScreen from "./pages/ContactUs";
import WorkWithUsScreen from "./pages/WorkWithUs";
import ArticleScreen from "./pages/Article";
import ExchangeForMoneyScreen from "./pages/ExchangeForMoney";
import InstallmentScreen from "./pages/Installment";
import InstallmentListScreen from "./pages/Installment/list";
import ProductScreen from "./pages/Installment/product";
import ProductRegisterScreen from "./pages/Installment/productRegister";
import ProductRegisterCompletedScreen from "./pages/Installment/productRegisterCompleted";

export const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/' }} />
        )
      }
    />
  )
}

export const AuthRoute = ({
  component: Component,
  isAuthenticated,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Redirect to={{ pathname: '/home' }} />
        ) : (
          <Component {...props} />
        )
      }
    />
  )
}

const App = (props) => {

  const [forceRefresh] = useState(false)

  // var userData = getUserData();
  const isAuthenticated = false;
  // const isAuthenticated = true;
  // const isAuthenticated = userData.status ?? false;

  return (
    <>
      <BrowserRouter
        // basename={process.env.REACT_APP_API_URL}
        forceRefresh={forceRefresh}
      >
        <Switch>
          <Route exact path="/" component={HomeScreen} />
          <Route exact path="/faqs" component={FaqScreen} />
          <Route exact path="/about-us" component={AboutUsScreen} />
          <Route exact path="/contact-us" component={ContactUsScreen} />
          <Route exact path="/work-with-us" component={WorkWithUsScreen} />
          <Route exact path="/article" component={ArticleScreen} />
          <Route exact path="/exchange-for-money" component={ExchangeForMoneyScreen} />
          <Route exact path="/installment" component={InstallmentScreen} />
          <Route exact path="/installment-list" component={InstallmentListScreen} />
          <Route exact path="/product/:id" component={ProductScreen} />
          <Route exact path="/product-register" component={ProductRegisterScreen} />
          <Route exact path="/product-register/:product_id" component={ProductRegisterScreen} />
          <Route exact path="/product-register-complete" component={ProductRegisterCompletedScreen} />
          <Route path="*" component={NotFound} />
        </Switch>
      </BrowserRouter>
      <ToastContainer />
    </>
  )
}

export default App;
