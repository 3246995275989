import React from 'react';
import { Link } from 'react-router-dom'

import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import WarehouseService from '../../components/Layout/WarehouseService';
import { Helmet } from 'react-helmet';
import strings from '../../components/Language';

class FaqScreen extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		}
	}

	async componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		return (
			<>
				<Helmet><title>{"Device Warehouse | " + strings["FAQ"]}</title></Helmet>
				<Header />

				<section className="value">
					<div className="container">
						<div className="row">
							<div className="col-12 mb-3 text-center">
								<h2>{strings["FAQ"]}</h2>
							</div>
							<div className="col-md-4 text-center">
								<picture className="smpic"><img src="assets/images/faq.png" alt="" /></picture>
							</div>
							<div className="col-md-8">
								<div className="accordion" id="accordionExample">
									<div className="accordion-item">
										<h2 className="accordion-header">
											<button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
												{strings["faq_que_1"]}
											</button>
										</h2>
										<div id="collapseOne" className="accordion-collapse collapse show"
											data-bs-parent="#accordionExample">
											<div className="accordion-body">
												<p dangerouslySetInnerHTML={{ __html: strings["faq_ans_1_1"] }} />
											</div>
										</div>
									</div>
									<div className="accordion-item">
										<h2 className="accordion-header">
											<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
												data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
												{strings["faq_que_2"]}
											</button>
										</h2>
										<div id="collapseTwo" className="accordion-collapse collapse"
											data-bs-parent="#accordionExample">
											<div className="accordion-body">
												<p dangerouslySetInnerHTML={{ __html: strings["faq_ans_2_1"] }} />
												<p dangerouslySetInnerHTML={{ __html: strings["faq_ans_2_2"] }} />
												<p dangerouslySetInnerHTML={{ __html: strings["faq_ans_2_3"] }} />
												<p dangerouslySetInnerHTML={{ __html: strings["faq_ans_2_4"] }} />
												<p dangerouslySetInnerHTML={{ __html: strings["faq_ans_2_5"] }} />
											</div>
										</div>
									</div>
									<div className="accordion-item">
										<h2 className="accordion-header">
											<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
												data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
												{strings["faq_que_3"]}
											</button>
										</h2>
										<div id="collapseThree" className="accordion-collapse collapse"
											data-bs-parent="#accordionExample">
											<div className="accordion-body">
												<p dangerouslySetInnerHTML={{ __html: strings["faq_ans_3_1"] }} />
												<p dangerouslySetInnerHTML={{ __html: strings["faq_ans_3_2"] }} />
											</div>
										</div>
									</div>
									<div className="accordion-item">
										<h2 className="accordion-header">
											<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
												data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
												{strings["faq_que_4"]}
											</button>
										</h2>
										<div id="collapseFour" className="accordion-collapse collapse"
											data-bs-parent="#accordionExample">
											<div className="accordion-body">
												<p dangerouslySetInnerHTML={{ __html: strings["faq_ans_4_1"] }} />
												<p dangerouslySetInnerHTML={{ __html: strings["faq_ans_4_2"] }} />
												<p dangerouslySetInnerHTML={{ __html: strings["faq_ans_4_3"] }} />
												<p dangerouslySetInnerHTML={{ __html: strings["faq_ans_4_4"] }} />
											</div>
										</div>
									</div>
									<div className="accordion-item">
										<h2 className="accordion-header">
											<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
												data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
												{strings["faq_que_5"]}
											</button>
										</h2>
										<div id="collapseFive" className="accordion-collapse collapse"
											data-bs-parent="#accordionExample">
											<div className="accordion-body">
												<p dangerouslySetInnerHTML={{ __html: strings["faq_ans_5_1"] }} />
												<p dangerouslySetInnerHTML={{ __html: strings["faq_ans_5_2"] }} />
											</div>
										</div>
									</div>
									<div className="accordion-item">
										<h2 className="accordion-header">
											<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
												data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
												{strings["faq_que_6"]}
											</button>
										</h2>
										<div id="collapseSix" className="accordion-collapse collapse"
											data-bs-parent="#accordionExample">
											<div className="accordion-body">
												<p dangerouslySetInnerHTML={{ __html: strings["faq_ans_6_1"] }} />
												<p dangerouslySetInnerHTML={{ __html: strings["faq_ans_6_2"] }} />
												<p dangerouslySetInnerHTML={{ __html: strings["faq_ans_6_3"] }} />
											</div>
										</div>
									</div>
									<div className="accordion-item">
										<h2 className="accordion-header">
											<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
												data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
												{strings["faq_que_7"]}
											</button>
										</h2>
										<div id="collapseSeven" className="accordion-collapse collapse"
											data-bs-parent="#accordionExample">
											<div className="accordion-body">
												<p dangerouslySetInnerHTML={{ __html: strings["faq_ans_7_1"] }} />
												<p dangerouslySetInnerHTML={{ __html: strings["faq_ans_7_2"] }} />
											</div>
										</div>
									</div>
									<div className="accordion-item">
										<h2 className="accordion-header">
											<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
												data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
												{strings["faq_que_8"]}
											</button>
										</h2>
										<div id="collapseEight" className="accordion-collapse collapse"
											data-bs-parent="#accordionExample">
											<div className="accordion-body">
												<p dangerouslySetInnerHTML={{ __html: strings["faq_ans_8_1"] }} />
												<p dangerouslySetInnerHTML={{ __html: strings["faq_ans_8_2"] }} />
											</div>
										</div>
									</div>
									<div className="accordion-item">
										<h2 className="accordion-header">
											<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
												data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
												{strings["faq_que_9"]}
											</button>
										</h2>
										<div id="collapseNine" className="accordion-collapse collapse"
											data-bs-parent="#accordionExample">
											<div className="accordion-body">
												<p dangerouslySetInnerHTML={{ __html: strings["faq_ans_9_1"] }} />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<WarehouseService />

				<Footer />
			</>
		);
	}
}

export default FaqScreen;
