import React from 'react';
import { Link, NavLink } from 'react-router-dom'

import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import WarehouseService from '../../components/Layout/WarehouseService';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { api } from '../../components/config/api';
import strings from '../../components/Language';

class InstallmentListScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: localStorage.getItem("language"),
            data: [],
            products: [],
        }
    }

    async componentDidMount() {
        window.scrollTo(0, 0)
        this.getData(api.product);
    }

    getData(API) {
        if (API) { } else { return false; };
        var self = this;
        axios({
            method: 'GET',
            url: API,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json',
                'Accept-Language': self.state.lang,
            }
        }).then(function (ress) {

            if (ress.data && ress.data.error === 200) {
                self.setState({
                    data: (ress.data && ress.data.data) ? ress.data.data : [],
                    products: (ress.data && ress.data.data && ress.data.data.data) ? ress.data.data.data : [],
                });
            }
        }).catch(function (e) {
            console.log(e);
        });
    };

    render() {
        return (
            <>
                <Helmet><title>{"Device Warehouse | " + strings["Installment"]}</title></Helmet>
                <Header />

                <section className="seller">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 position-relative">
                                <a onClick={() => this.props.history.goBack()} className="btn btn-back px-4"><i className="fa fa-angle-left me-2"></i>Back</a>
                                <h2>{strings["Installment"]}</h2>
                            </div>
                            <div className="filter">
                                <div className="row">
                                    <div className="col-8 col-md-4">
                                        <select className="form-select" aria-label="Default select example">
                                            <option defaultValue={""}>{strings["Sort by Popularity"]}</option>
                                        </select>
                                    </div>
                                    {/* <div className="col-4 col-md-8 ps-0 text-start">
                                        <a href="#" className="fa fa-th-large active"></a> 
                                        <a href="#" className="fa fa-list"></a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="row g-4">
                            {this.state.products.map((item, i) =>
                                <div key={i + 100} className="col-6 col-md-3">
                                    <div className="display">
                                        <span className="badge">{strings["Hot"]}</span>
                                        <picture className='product_img insta_list'>
                                            <img src={item.image_url ? item.image_url : "assets/images/iphone-13.png"} alt="" />
                                        </picture>
                                        <h4 style={{ marginTop: 5 }}>{item.en_name}</h4>
                                        <div dangerouslySetInnerHTML={{ __html: item.en_detail.substring(0, 20) }} />
                                        <Link to={`/product/${item.id}`} className="btn">
                                            {strings["Choose a pattern"]}<img src="assets/images/arw_rud.svg" className="ms-2" alt="" />
                                        </Link>
                                    </div>
                                </div>
                            )}

                            {(this.state.data && this.state.data.links) ?
                                <div className="col-12 mt-4">
                                    <ul className="pagination justify-content-center">
                                        {this.state.data.links.map((item, i) =>
                                            <>
                                                {(item.label == "&laquo; Previous" && item.url) ?
                                                    <li className={item.active ? "page-item active" : "page-item"}>
                                                        <a style={{ cursor: "pointer" }} className="page-link" onClick={() => this.getData(item.url)}><i className="fa fa-angle-left"></i></a>
                                                    </li>
                                                    : null}
                                                {((item.label != "Next &raquo;") && (item.label != "&laquo; Previous") && item.url) ?
                                                    <li className={item.active ? "page-item active" : "page-item"}>
                                                        <a style={{ cursor: "pointer" }} className="page-link" onClick={() => this.getData(item.url)}>{item.label}</a>
                                                    </li>
                                                    : null}
                                                {(item.label == "Next &raquo;" && item.url) ?
                                                    <li className={item.active ? "page-item active" : "page-item"}>
                                                        <a style={{ cursor: "pointer" }} className="page-link" onClick={() => this.getData(item.url)}><i className="fa fa-angle-right"></i></a>
                                                    </li>
                                                    : null}
                                            </>
                                        )}
                                    </ul>
                                </div>
                                : null}
                        </div>
                    </div>
                </section>

                <WarehouseService />
                <Footer />
            </>
        );
    }
}

export default InstallmentListScreen;
