import React from 'react';
import { Link, NavLink } from 'react-router-dom'
import QRCode from "react-qr-code";

import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import { Helmet } from 'react-helmet';
import strings from '../../components/Language';

class ProductRegisterCompletedScreen extends React.Component {
    constructor(props) {
        super(props);
        const stateData = (this.props && this.props.location && this.props.location.state && this.props.location.state.data) ? this.props.location.state.data : {};
        const prodcutData = (this.props && this.props.location && this.props.location.state && this.props.location.state.prodcutData) ? this.props.location.state.prodcutData : [];
        console.log(prodcutData, stateData);
        this.state = {
            stateData: stateData,
            prodcutData: prodcutData,
            steps: [
                { id: 1, step_id: 1, name: strings["Register"], class_name_col: "col-2", class_name: "number" },
                { id: 2, step_id: 2, name: strings["Product"], class_name_col: "col-3", class_name: "number mx-auto" },
                { id: 3, step_id: 3, name: strings["Personal"], class_name_col: "col-2", class_name: "number mx-auto" },
                { id: 4, step_id: 4, name: strings["Occupation"], class_name_col: "col-3", class_name: "number mx-auto" },
                { id: 5, step_id: 5, name: strings["Contact"], class_name_col: "col-2", class_name: "number float-end" },
            ],
        }
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <>
                <Helmet><title>{"Device Warehouse | " + strings["Installment"]}</title></Helmet>
                <Header />
                <div className="container">
                    <div className="exchange">
                        <div className="row gx-5">
                            <div className="col-12 mb-4 text-center position-relative">
                                {(this.state.prodcutData && this.state.prodcutData.register_for == 1) ?
                                    <h2>{strings["Installment"]}</h2>
                                    : ""}
                                {(this.state.prodcutData && this.state.prodcutData.register_for == 2) ?
                                    <h2>{strings["iPhone Money Exchange"]}</h2>
                                    : ""}
                            </div>
                            <div className="col-12">
                                <div className="line mb-4">
                                    <div className="row">
                                        {this.state.steps.map((item, i) =>
                                            <>
                                                <div key={"_steps_" + i} className={item.class_name_col}>
                                                    <div className={item.class_name}>
                                                        <span className="complete">
                                                            <img src="assets/images/icn_tick.svg" width="w-100" alt="" />
                                                        </span>
                                                        {item.name}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 py-5 mb-5 text-center">
                                <h2 className='mb-5'>{strings["This is your reference number"]} <span style={{ color: "#f37521" }}>{(this.state.stateData && this.state.stateData.reference_number) ? this.state.stateData.reference_number : "-"}</span> {strings["you can save it."]}</h2>
                                <p style={{ fontWeight: "normal" }}>{strings["Step 1: Add Device Warehouce LINE ID"]} <a href='https://line.me/R/ti/p/@490zukar'>@DeviceWH</a></p>
                                <div style={{ height: "auto", margin: "0 auto", maxWidth: 100, width: "100", marginBottom: 20 }}>
                                    <QRCode
                                        size={256}
                                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                        fgColor='green'
                                        value={"https://line.me/R/ti/p/@490zukar"}
                                        viewBox={`0 0 256 256`}
                                    />
                                </div>
                                <p style={{ fontWeight: "normal" }}>{strings["Step 2: Send us your reference number to check your approval status."]}</p>
                                <img src="assets/images/fnh.png" alt="" /><br /><br />
                                <p>{strings["Successfully Registered"]}</p>
                                {(this.state.prodcutData && this.state.prodcutData.branch_booking_time) ?
                                    <p style={{ fontWeight: "normal" }}>{strings["Our Operation Hours Are"]} {/*this.state.prodcutData.branch_booking_time*/} 12pm - 9pm {strings["Daily"]}</p>
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </>
        );
    }
}

export default ProductRegisterCompletedScreen;