import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import provinces from "./../../components/Geography/provinces.json";
import districts from "./../../components/Geography/districts.json";
import subdistricts from "./../../components/Geography/subdistricts.json";

import React from 'react';
import { Link, NavLink } from 'react-router-dom'

import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import WarehouseService from '../../components/Layout/WarehouseService';
import Select from '@mui/material/Select';
import { MenuItem } from '@mui/material';
import { Helmet } from 'react-helmet';
import { api } from '../../components/config/api';
import axios from 'axios';
import { toast } from 'react-toastify';
import { validNumber } from '../../components/Util';
import strings from '../../components/Language';
import moment from "moment/moment";
var _ = require('lodash');


class ProductRegisterScreen extends React.Component {
    constructor(props) {
        super(props);
        const params_id = (this.props && this.props.match && this.props.match.params && this.props.match.params.product_id) ? this.props.match.params.product_id : null;
        const agent_code = (this.props && this.props.location && this.props.location.search) ? _.split(this.props.location.search, "agent=")[1] : null;
        this.state = {
            lang: localStorage.getItem("language"),
            params_id: params_id,
            agent_code: agent_code,
            step: 1,
            steps: [
                { id: 1, step_id: 1, name: strings["Register"], class_name_col: "col-2", class_name: "number" },
                { id: 2, step_id: 2, name: strings["Product"], class_name_col: "col-3", class_name: "number mx-auto" },
                { id: 3, step_id: 3, name: strings["Personal"], class_name_col: "col-2", class_name: "number mx-auto" },
                { id: 4, step_id: 4, name: strings["Occupation"], class_name_col: "col-3", class_name: "number mx-auto" },
                { id: 5, step_id: 5, name: strings["Contact"], class_name_col: "col-2", class_name: "number float-end" },
            ],
            occupation: 1,
            provinces: provinces,
            districts: [],
            subdistricts: [],
            product_data: [],
            product: [],
            color_list: [],
            capacity_list: [],
            dataStep1: [],
            dataStep2: [],
            dataStep3: [],
            dataStep4: [],
            dataStep5: [],
            available_time_slot_list: [],
            selected_product: [],
            selected_product_amount: 0,
            product_image_url: null,

            errorMessage: [],


            product_id: (params_id) ? params_id : null,
            down_payment_rate: 0,
            installment_amount: 0,

            register_for: (params_id) ? 1 : "",
            first_name: "",
            last_name: "",
            line_id: "",
            mobile: "",
            dial_code: "+66",

            capacity_id: "",
            color_id: "",
            installment_month: "",
            branch_id: "",
            branch_booking_date: new Date(),
            branch_booking_time: "",
            methods_of_purchase_id: "",
            quantity: "",
            id_card_number: "",
            mobile_personal: "",
            prefix: "",
            first_name_personal: "",
            last_name_personal: "",
            current_address: "",
            zip_code: "",
            province: "",
            district: "",
            subdistrict: "",
            facebook: "",
            id_line: "",
            number_of_people: "",
            sim_plan: "",

            occupation_type: 1,

            career: "",
            nature_of_business: "",
            company_name: "",
            place_of_work: "",
            job_title: "",
            working_age: "",
            monthly_income: "",
            work_mobile: "",
            income_document_type: "",
            university: "",
            faculty: "",
            major: "",
            education_level: "",
            year: "",
            school_year_attended: "",
            average_income: "",
            student_card: "",
            student_income_document_type: "",
            student_income_document: "",
            income_document: "",

            relationship1: "",
            mobile1: "",
            first_name1: "",
            last_name1: "",
            relationship2: "",
            mobile2: "",
            first_name2: "",
            last_name2: "",
            referred_from: "",
            referred_from_manually: "",

            accept_tc: false,

            amount_breakdown: {},
            month_amount: {
                month6_amount: 0,
                month9_amount: 0,
                month12_amount: 0,
            },
        }
    }

    async componentDidMount() {
        var self = this;
        window.scrollTo(0, 0);
        setTimeout(() => {
            if (self.state.product_id) {
                self.getData(api.product + "/" + self.state.product_id);
                // self.getDataProductRegisterStep(2, api.product_register_step_2 + "?product=" + self.state.product_id);
            } else {
                // self.getDataProductRegisterStep(2, api.product_register_step_2);
            }
            self.getDataProductRegisterStep(1, api.product_register_step_1);
            // self.getDataProductRegisterStep(3, api.product_register_step_3);
            // self.getDataProductRegisterStep(4, api.product_register_step_4);
            // self.getDataProductRegisterStep(5, api.product_register_step_5);
        });

        if (self.state.agent_code) {
            await localStorage.setItem('agent_code', self.state.agent_code);
        }
        console.log(localStorage.getItem('agent_code'));

    }

    getData(API) {
        if (API) { } else { return false; }
        var self = this;
        axios({
            method: 'GET',
            url: API,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json',
                'Accept-Language': self.state.lang,
            }
        }).then(function (ress) {

            if (ress.data && ress.data.error === 200) {
                self.setState({
                    product_data: (ress.data && ress.data.data) ? ress.data.data : [],
                    product: (ress.data && ress.data.data && ress.data.data.product) ? ress.data.data.product : [],
                    color_list: (ress.data && ress.data.data && ress.data.data.color_list) ? ress.data.data.color_list : [],
                    capacity_list: (ress.data && ress.data.data && ress.data.data.capacity_list) ? ress.data.data.capacity_list : [],
                });
            }
        }).catch(function (e) {
            console.log(e);
        });
    };

    getDataProductRegisterStep(current_step, API) {
        if (API) { } else { return false; }
        var self = this;
        self.setState({ errorMessage: [] });
        axios({
            method: 'GET',
            url: API,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json',
                'Accept-Language': self.state.lang,
            }
        }).then(function (ress) {

            if (ress.data.error == 202 || ress.data.error == '202') {
                if (ress.data.validation && ress.data.validation.length != 0) {
                    self.setState({ errorMessage: ress.data.validation });
                } else {
                    toast.error(ress.data.message);
                }
            } else {
                if (current_step == 1) {
                    self.setState({ dataStep1: (ress.data && ress.data.data) ? ress.data.data : [], });
                }
                if (current_step == 2) {

                    var dataStep2_data = (ress.data && ress.data.data) ? ress.data.data : [];
                    var dataStep2_product_list = (dataStep2_data && dataStep2_data.product_list) ? dataStep2_data.product_list : [];
                    if (self.state.register_for == 1) {
                        dataStep2_data['product_list'] = _.filter(dataStep2_product_list, function (o) { return o.type == 0 });
                    } else if (self.state.register_for == 2) {
                        dataStep2_data['product_list'] = _.filter(dataStep2_product_list, function (o) { return o.type == 1 });
                    }
                    self.setState({ dataStep2: dataStep2_data });

                    setTimeout(() => {
                        var selected_product = (ress.data && ress.data.data && ress.data.data.selected_product) ? ress.data.data.selected_product : [];
                        var variant_data = _.uniqBy(selected_product['variant'], 'capacity_id');
                        selected_product['selected_capacity_variant'] = variant_data ? variant_data : [];
                        self.setState({
                            selected_product: selected_product,
                            selected_product_amount: (ress.data && ress.data.data && ress.data.data.selected_product && ress.data.data.selected_product.price) ? ress.data.data.selected_product.price : 0,
                            product_image_url: (ress.data && ress.data.data && ress.data.data.selected_product && ress.data.data.selected_product.image_url) ? ress.data.data.selected_product.image_url : 0,
                        });
                    });
                }
                if (current_step == 3) {
                    self.setState({ dataStep3: (ress.data && ress.data.data) ? ress.data.data : [], });
                }
                if (current_step == 4) {
                    self.setState({ dataStep4: (ress.data && ress.data.data) ? ress.data.data : [], });
                }
                if (current_step == 5) {
                    self.setState({ dataStep5: (ress.data && ress.data.data) ? ress.data.data : [], });
                }
            }
        }).catch(function (e) {
            console.log(e);
        });
    };


    getDataAvailableTimeSlot(API) {
        if (API) { } else { return false; }
        var self = this;
        self.setState({ errorMessage: [] });

        var json = {
            branch_id: self.state.branch_id,
            branch_booking_date: moment(self.state.branch_booking_date).format("YYYY-MM-DD")
        };

        axios({
            method: 'POST',
            url: API,
            data: json,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json',
                'Accept-Language': self.state.lang,
            }
        }).then(function (ress) {

            if (ress.data.error == 202 || ress.data.error == '202') {
                if (ress.data.validation && ress.data.validation.length != 0) {
                    self.setState({ errorMessage: ress.data.validation });
                } else {
                    toast.error(ress.data.message);
                }
            } else {
                self.setState({ available_time_slot_list: _.values((ress.data && ress.data.data && ress.data.data.available_time_slot_list) ? ress.data.data.available_time_slot_list : []) });
            }
        }).catch(function (e) {
            console.log(e);
        });
    };

    checkStepValidation(current_step) {
        var self = this;

        var API = null;
        var json = {};
        if (current_step == 1) {
            API = api.product_register_step_1_validate;
            json = {
                product_id: this.state.product_id,
                register_for: self.state.register_for,
                first_name: self.state.first_name,
                last_name: self.state.last_name,
                line_id: self.state.line_id,
                mobile: self.state.mobile,
                dial_code: self.state.dial_code,
            }
        } else if (current_step == 2) {
            json = {
                product_id: this.state.product_id,
                capacity_id: self.state.capacity_id,
                color_id: self.state.color_id,
                installment_month: self.state.installment_month,
                branch_id: self.state.branch_id,
                methods_of_purchase_id: self.state.methods_of_purchase_id,
                branch_booking_date: moment(self.state.branch_booking_date).format("YYYY-MM-DD"),
                branch_booking_time: self.state.branch_booking_time,
            }
            API = api.product_register_step_2_validate;
        } else if (current_step == 3) {
            json = {
                id_card_number: self.state.id_card_number,
                mobile_personal: self.state.mobile_personal,
                prefix: self.state.prefix,
                first_name_personal: self.state.first_name_personal,
                last_name_personal: self.state.last_name_personal,
                current_address: self.state.current_address,
                zip_code: self.state.zip_code,
                province: self.state.province,
                district: self.state.district,
                subdistrict: self.state.subdistrict,
                facebook: self.state.facebook,
                id_line: (self.state.register_for != 1) ? self.state.id_line : "-",
                number_of_people: self.state.number_of_people,
                sim_plan: self.state.sim_plan,
            }
            API = api.product_register_step_3_validate;
        } else if (current_step == 4) {
            if (self.state.occupation_type == 1) {
                json = {
                    occupation_type: self.state.occupation_type,
                    career: self.state.career,
                    nature_of_business: self.state.nature_of_business,
                    company_name: self.state.company_name,
                    place_of_work: self.state.place_of_work,
                    job_title: self.state.job_title,
                    working_age: self.state.working_age,
                    monthly_income: self.state.monthly_income,
                    work_mobile: self.state.work_mobile,
                    income_document_type: self.state.income_document_type,
                    income_document: self.state.income_document,
                }
            }
            if (self.state.occupation_type == 2) {
                json = {
                    occupation_type: self.state.occupation_type,
                    university: self.state.university,
                    faculty: self.state.faculty,
                    major: self.state.major,
                    education_level: self.state.education_level,
                    year: self.state.year,
                    school_year_attended: self.state.school_year_attended,
                    average_income: self.state.average_income,
                    student_card: self.state.student_card,
                    student_income_document_type: self.state.student_income_document_type,
                    student_income_document: self.state.student_income_document,
                    income_document: self.state.income_document,
                }
            }
            API = api.product_register_step_4_validate;
        } else if (current_step == 5) {
            json = {
                relationship1: self.state.relationship1,
                mobile1: self.state.mobile1,
                first_name1: self.state.first_name1,
                last_name1: self.state.last_name1,
                relationship2: self.state.relationship2,
                mobile2: self.state.mobile2,
                first_name2: self.state.first_name2,
                last_name2: self.state.last_name2,
                referred_from: self.state.referred_from,
                referred_from_manually: self.state.referred_from_manually,
            }
            API = api.product_register_step_5_validate;
        }
        if (API) { } else { return false; }

        self.setState({ errorMessage: [] });
        axios({
            method: 'POST',
            url: API,
            data: json,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json;multipart/form-data;boundary=SOME_BOUNDARY',
                'Accept-Language': self.state.lang,
            }
        }).then(function (ress) {
            if (ress.data.error == 202 || ress.data.error == '202') {
                if (ress.data.validation && ress.data.validation.length != 0) {
                    self.setState({ errorMessage: ress.data.validation });
                } else {
                    toast.error(ress.data.message);
                }
            } else {
                if (current_step == 1) {
                    self.setState({ step: 2 })
                    if (self.state.product_id) {
                        self.getDataProductRegisterStep(2, api.product_register_step_2 + "?product=" + self.state.product_id);
                    } else {
                        self.getDataProductRegisterStep(2, api.product_register_step_2);
                    }
                } else if (current_step == 2) {
                    self.setState({ step: 3 })
                    self.getDataProductRegisterStep(3, api.product_register_step_3);
                } else if (current_step == 3) {
                    self.setState({ step: 4 })
                    self.getDataProductRegisterStep(4, api.product_register_step_4);
                } else if (current_step == 4) {
                    self.setState({ step: 5 })
                    self.getDataProductRegisterStep(5, api.product_register_step_5);
                } else if (current_step == 5) {
                    self.finalSubmit()
                }
            }
        }).catch(function (e) {
            console.log(e);
        });
    };

    finalSubmit() {
        var self = this;
        var json = {
            agent_code: localStorage.getItem('agent_code'),
            register_for: self.state.register_for,
            first_name: self.state.first_name,
            last_name: self.state.last_name,
            line_id: self.state.line_id,
            mobile: self.state.mobile,
            dial_code: self.state.dial_code,
            product_id: this.state.product_id,
            capacity_id: self.state.capacity_id,
            color_id: self.state.color_id,
            installment_month: self.state.installment_month,
            branch_id: self.state.branch_id,
            methods_of_purchase_id: self.state.methods_of_purchase_id,
            branch_booking_date: moment(self.state.branch_booking_date).format("YYYY-MM-DD"),
            branch_booking_time: self.state.branch_booking_time,
            id_card_number: self.state.id_card_number,
            mobile_personal: self.state.mobile_personal,
            prefix: self.state.prefix,
            first_name_personal: self.state.first_name_personal,
            last_name_personal: self.state.last_name_personal,
            current_address: self.state.current_address,
            zip_code: self.state.zip_code,
            province: self.state.province,
            district: self.state.district,
            subdistrict: self.state.subdistrict,
            facebook: self.state.facebook,
            id_line: self.state.id_line,
            number_of_people: self.state.number_of_people,
            sim_plan: self.state.sim_plan,
            occupation_type: self.state.occupation_type,
            career: self.state.career,
            nature_of_business: self.state.nature_of_business,
            company_name: self.state.company_name,
            place_of_work: self.state.place_of_work,
            job_title: self.state.job_title,
            working_age: self.state.working_age,
            monthly_income: self.state.monthly_income,
            work_mobile: self.state.work_mobile,
            income_document_type: self.state.income_document_type,
            income_document: self.state.income_document,
            occupation_type: self.state.occupation_type,
            university: self.state.university,
            faculty: self.state.faculty,
            major: self.state.major,
            education_level: self.state.education_level,
            year: self.state.year,
            school_year_attended: self.state.school_year_attended,
            average_income: self.state.average_income,
            student_card: self.state.student_card,
            student_income_document_type: self.state.student_income_document_type,
            student_income_document: self.state.student_income_document,
            income_document: self.state.income_document,
            relationship1: self.state.relationship1,
            mobile1: (self.state.mobile1) ? self.state.mobile1 : "",
            first_name1: self.state.first_name1,
            last_name1: self.state.last_name1,
            relationship2: self.state.relationship2,
            mobile2: (self.state.mobile2) ? self.state.mobile2 : "",
            first_name2: self.state.first_name2,
            last_name2: self.state.last_name2,
            referred_from: self.state.referred_from,
            referred_from_manually: self.state.referred_from_manually,
        }

        if (self.state.accept_tc) {

        } else {
            toast.error(strings["Please check terms of the agreement."]);
            return false;
        }

        self.setState({ errorMessage: [] });
        axios({
            method: 'POST',
            url: api.product_register_submit,
            data: json,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json;multipart/form-data;boundary=SOME_BOUNDARY',
                'Accept-Language': self.state.lang,
            }
        }).then(function (ress) {
            if (ress.data.error == 202 || ress.data.error == '202') {
                if (ress.data.validation && ress.data.validation.length != 0) {
                    self.setState({ errorMessage: ress.data.validation });
                } else {
                    toast.error(ress.data.message);
                }
            } else {
                toast.success(ress.data.message);
                console.log(ress.data);
                // self.props.history.push('/product-register-complete', { prodcutData: json, data: ress.data.data })
                self.props.history.push({
                    pathname: '/product-register-complete',
                    state: { prodcutData: json, data: ress.data.data }
                })
            }
        }).catch(function (e) {
            console.log(e);
        });
    };

    handleChange = (event) => {
        this.setState({ occupation: event.target.value })
    };

    onChangeProduct(e) {
        var self = this;
        self.setState({
            product_id: e.target.value,
            down_payment_rate: 0,
            capacity_id: '',
            color_id: '',
            installment_month: '',
            branch_id: '',
            methods_of_purchase_id: '',
            selected_product_amount: e.target.childNodes[e.target.selectedIndex].getAttribute('data-price'),
            product_image_url: e.target.childNodes[e.target.selectedIndex].getAttribute('data-product-image-url')
        });

        self.getData(api.product + "/" + e.target.value);
        self.getDataProductRegisterStep(2, api.product_register_step_2 + "?product=" + e.target.value);
    }

    onChangeCapacity(e) {
        var self = this;
        self.setState({ capacity_id: e.target.value });
        self.setState({ color_id: '', installment_month: '', installment_amount: 0, });

        var selected_product = self.state.selected_product;
        var variant_data = selected_product['variant'];
        var selected_capacity_colors = _.filter(variant_data, function (o) { return (o.capacity_id == e.target.value); });
        selected_product['selected_capacity_colors'] = selected_capacity_colors ? selected_capacity_colors : [];
        selected_product['amount_breakdown'] = (find_capacity_values && find_capacity_values.amount_breakdown) ? find_capacity_values.amount_breakdown : {};
        var find_capacity_values = _.find(variant_data, function (o) { return (o.capacity_id == e.target.value) });
        var month_amount = {
            month6_amount: find_capacity_values.month6_amount,
            month9_amount: find_capacity_values.month9_amount,
            month12_amount: find_capacity_values.month12_amount,
        }
        self.setState({
            month_amount: month_amount,
            down_payment_rate: find_capacity_values.downpayment_amount,
            selected_product: selected_product
        });

    }

    onchangeProvince(e) {
        this.setState({ province: e.target.value ? e.target.value : "" });
        var provinceCode = e.target.childNodes[e.target.selectedIndex].getAttribute('data-provinceCode');
        if (provinceCode) {
            var districts_data = _.filter(districts, function (o) { return o.provinceCode == provinceCode });
            this.setState({ districts: districts_data, district: "" });
        }
    }

    onchangeDistrict(e) {
        console.log(e);
        this.setState({ district: e.target.value ? e.target.value : "" });
        var districtCode = e.target.childNodes[e.target.selectedIndex].getAttribute('data-districtCode');
        if (districtCode) {
            var subdistricts_data = _.filter(subdistricts, function (o) { return o.districtCode == districtCode });
            this.setState({ subdistricts: subdistricts_data, subdistrict: "", });
        }
    }

    onchangeSubDistrict(e) {
        this.setState({ subdistrict: e.target.value ? e.target.value : "" });
        var subdistrictCode = e.target.childNodes[e.target.selectedIndex].getAttribute('data-subdistrictCode');
    }

    changeBranchs(branch_id) {
        console.log(branch_id);
        this.setState({
            branch_id: branch_id,
            available_time_slot_list: [],
            branch_booking_date: "",
            branch_booking_time: ""
        });
    }

    changeDate(date) {
        this.setState({ branch_booking_date: date });
        this.setState({ available_time_slot_list: [] });
        setTimeout(() => {
            console.log(this.state.branch_booking_date);
            if (this.state.branch_booking_date) {
                this.getDataAvailableTimeSlot(api.product_register_available_time_slot);
            }
        });
    }

    checkOnlyNumber(data, key) {
        const regex = /^[0-9\b]+$/;
        if (data === "" || regex.test(data)) {
            if (key == "mobile") {
                this.setState({ mobile: data });
            }
            if (key == "mobile_personal") {
                this.setState({ mobile_personal: data });
            }
            if (key == "mobile1") {
                this.setState({ mobile1: data });
            }
            if (key == "mobile2") {
                this.setState({ mobile2: data });
            }
        }
    };

    render() {
        return (
            <>
                <Helmet><title>{"Device Warehouse | " + strings["Installment"]}</title></Helmet>
                <Header />

                <div className="container">
                    <div className="exchange">
                        <div className="row gx-5">

                            <div className="col-12 mb-4 text-center position-relative">
                                <a onClick={() => this.props.history.goBack()} className="btn btn-back px-4"><i className="fa fa-angle-left me-2"></i>{strings["Back"]}</a>
                                <h2>{(this.state.register_for == 1) ? strings["Installment"] : ""}</h2>
                                <h2>{(this.state.register_for == 2) ? strings["iPhone Money Exchange"] : ""}</h2>
                            </div>

                            <div className="col-md-12">
                                <div className="line mb-4">
                                    <div className="row">
                                        {this.state.steps.map((item, i) =>
                                            <div key={"_steps_" + i} className={item.class_name_col}>
                                                <div className={item.class_name}>
                                                    <span className={(this.state.step == item.step_id) ? "active" : ""}>{item.step_id}</span>
                                                    {item.name}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            {(this.state.step == 1) ?
                                <>
                                    <div className="col-12 mb-4">
                                        <div className="row justify-content-center">
                                            <div className="col-6 col-md-3">
                                                <div className="border rounded-3 p-3 mx-auto">
                                                    <div className="form-check">
                                                        <input className="form-check-input" onClick={() => this.setState({ register_for: 1 })} type="radio" name="register_for" id="register_for_1" defaultChecked={(this.state.register_for == 1) ? true : false} />
                                                        <label className="form-check-label" htmlFor="register_for_1">{strings["Installment"]}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="col-6 col-md-3">
                                                <div className="border rounded-3 p-3 mx-auto">
                                                    <div className="form-check">
                                                        <input className="form-check-input" onClick={() => this.setState({ register_for: 2 })} type="radio" name="register_for" id="register_for_2" defaultChecked={(this.state.register_for == 2) ? true : false} />
                                                        <label className="form-check-label" htmlFor="register_for_2">{strings["iPhone Money Exchange"]}</label>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                        <div className='row text-center'>
                                            {this.state.errorMessage.register_for && <div className='errorMessage'> {this.state.errorMessage.register_for[0]} </div>}
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col mb-3">
                                                <label className="form-label">{strings["First name"]} <span className='red'>*</span></label>
                                                <input onChange={(e) => this.setState({ first_name: e.target.value })} value={this.state.first_name} name="first_name" type="text" className="form-control" />
                                                <div className='row'> {this.state.errorMessage.first_name && <div className='errorMessage'> {this.state.errorMessage.first_name[0]} </div>}</div>
                                            </div>
                                            <div className="col mb-3">
                                                <label className="form-label">{strings["Last name"]} <span className='red'>*</span></label>
                                                <input onChange={(e) => this.setState({ last_name: e.target.value })} value={this.state.last_name} name="last_name" type="text" className="form-control" />
                                                <div className='row'> {this.state.errorMessage.last_name && <div className='errorMessage'> {this.state.errorMessage.last_name[0]} </div>}</div>
                                            </div>
                                            <div className="col mb-3">
                                                <label className="form-label">{strings["Line id"]} <span className='red'>*</span></label>
                                                <input onChange={(e) => this.setState({ line_id: e.target.value })} value={this.state.line_id} name="line_id" type="text" className="form-control" />
                                                <div className='row'> {this.state.errorMessage.line_id && <div className='errorMessage'> {this.state.errorMessage.line_id[0]} </div>}</div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col mb-3">
                                                <label className="form-label">{strings["Telephone Number"]} <span className='red'>*</span></label>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <input style={{ width: 50, marginRight: 10 }} onChange={(e) => this.setState({ dial_code: e.target.value })} value={this.state.dial_code} name="mobile" type="text" className="form-control" placeholder='+66' readOnly />
                                                    <input onChange={(e) => this.checkOnlyNumber(e.target.value, "mobile")} value={this.state.mobile} name="mobile" type="text" className="form-control" placeholder='01234567' />
                                                </div>
                                                <div className='row'>{this.state.errorMessage.dial_code && <div className='errorMessage'> {this.state.errorMessage.dial_code[0]} </div>}</div>
                                                <div className='row'>{this.state.errorMessage.mobile && <div className='errorMessage'> {this.state.errorMessage.mobile[0]} </div>}</div>
                                            </div>
                                        </div>
                                        {this.state.agent_code ?
                                            <div className="row">
                                                <div className="col mb-3">
                                                    <label className="form-label">{strings["Agent"]} <span className='red'></span></label>
                                                    <input onChange={(e) => this.setState({ agent_code: e.target.value })} value={this.state.agent_code} name="agent_code" type="text" className="form-control" readOnly disabled />
                                                    <div className='row'> {this.state.errorMessage.agent_code && <div className='errorMessage'> {this.state.errorMessage.agent_code[0]} </div>}</div>
                                                </div>
                                            </div>
                                            : null}
                                    </div>

                                    <div className="col-12 text-center">
                                        <button onClick={() => this.checkStepValidation(1)} href="productstep2.html" type="submit" className="btn btn-orange">{strings["Next"]}<i className="fa fa-angle-right"></i></button>
                                    </div>

                                    <WarehouseService />
                                </>
                                : null}

                            {(this.state.step == 2) ?
                                <>
                                    <div className="col-md-4 d-none d-md-block">
                                        <figure>
                                            <img src={(this.state.product_image_url) ? this.state.product_image_url : "./../assets/images/iphone-13.png"} alt="" />
                                        </figure>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="col-10 mx-auto d-sm-block d-md-none">
                                            <picture className='product_img'>
                                                <img src={"./../assets/images/iphone-13.png"} alt="" />
                                            </picture>
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">{strings["Product"]}<span>*</span></label>
                                            <select className="form-select" defaultValue={this.state.product_id} onChange={(e) => this.onChangeProduct(e)}>
                                                <option value={0}>{strings["Select products"]}</option>
                                                {this.state.dataStep2 && this.state.dataStep2.product_list ?
                                                    <>
                                                        {this.state.dataStep2.product_list.map((item, i) =>
                                                            <option selected={this.state.product_id == item.id ? true : false} value={item.id} data-price={(item.price) ? item.price : 0} data-product-image-url={(item.image_url) ? item.image_url : {}}>{item.en_name}</option>
                                                        )}
                                                    </>
                                                    : null}
                                            </select>
                                            {this.state.errorMessage.product_id && <div className='errorMessage'> {this.state.errorMessage.product_id[0]} </div>}
                                        </div>
                                        <div className="row">
                                            <div className="col-6 mb-3">
                                                <label className="form-label">{strings["Capacity"]} <span>*</span></label>
                                                <select className="form-select" defaultValue={this.state.capacity_id} onChange={(e) => this.onChangeCapacity(e)}>
                                                    <option value={0}>{strings["Select capacity"]}</option>
                                                    {this.state.selected_product && this.state.selected_product.selected_capacity_variant ?
                                                        <>
                                                            {this.state.selected_product.selected_capacity_variant.map((item, i) =>
                                                                <option selected={this.state.capacity_id == item.capacity.id ? true : false} value={item.capacity.id}>{item.capacity.name}</option>
                                                            )}
                                                        </>
                                                        : null}
                                                </select>
                                                {this.state.errorMessage.capacity_id && <div className='errorMessage'> {this.state.errorMessage.capacity_id[0]} </div>}
                                            </div>
                                            <div className="col-6 mb-3">
                                                <label className="form-label">{strings["Color"]} <span>*</span></label>
                                                <select className="form-select" defaultValue={this.state.color_id} onChange={(e) => this.setState({ color_id: e.target.value })}>
                                                    <option value={0}>{strings["Choose color"]}</option>
                                                    {this.state.selected_product && this.state.selected_product.selected_capacity_colors ?
                                                        <>
                                                            {this.state.selected_product.selected_capacity_colors.map((item, i) =>
                                                                <option selected={this.state.color_id == item.color.id ? true : false} value={item.color.id}>{item.color.name}</option>
                                                            )}
                                                        </>
                                                        : null}
                                                </select>
                                                {this.state.errorMessage.color_id && <div className='errorMessage'> {this.state.errorMessage.color_id[0]} </div>}
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">{strings["Maximum period"]} <span>*</span></label>
                                            <select className="form-select" defaultValue={this.state.installment_month} onChange={(e) => this.setState({ installment_month: e.target.value, installment_amount: e.target.childNodes[e.target.selectedIndex].getAttribute('installment-amount') })}>
                                                <option value={0}>{strings["Choose Maximum period"]}</option>
                                                {this.state.dataStep2 && this.state.dataStep2.installment_month_list ?
                                                    <>
                                                        {this.state.dataStep2.installment_month_list.map((item, i) =>
                                                            <option selected={this.state.installment_month == item.month ? true : false} value={item.month} installment-amount={this.state.month_amount["month" + item.month + "_amount"]}>{item.name}</option>
                                                        )}
                                                    </>
                                                    : null}
                                            </select>
                                            {this.state.errorMessage.installment_month && <div className='errorMessage'> {this.state.errorMessage.installment_month[0]} </div>}
                                        </div>
                                        <div className="row">
                                            <div className="col mb-3">
                                                <label className="form-label">{strings["Downpayment"]}</label>
                                                <input onChange={(e) => this.setState({ down_payment_rate: e.target.value })} value={this.state.down_payment_rate} name="down_payment_rate" type="text" className="form-control" readOnly />
                                            </div>
                                            <div className="col mb-3">
                                                <label className="form-label">{strings["Monthly Installement"]}</label>
                                                <input onChange={(e) => this.setState({ installment_amount: e.target.value })} value={this.state.installment_amount} name="installment_amount" type="text" className="form-control" readOnly />
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">{strings["Methods of purchase"]} <span>*</span></label>
                                            <select className="form-select" defaultValue={this.state.methods_of_purchase_id} onChange={(e) => this.setState({ methods_of_purchase_id: e.target.value, branch_id: "" })}>
                                                <option value={0}>{strings["Choose a methods of purchase"]}</option>
                                                {this.state.dataStep2 && this.state.dataStep2.methods_of_purchase_list ?
                                                    <>
                                                        {this.state.dataStep2.methods_of_purchase_list.map((item, i) =>
                                                            <>
                                                                {this.state.register_for == 2 && item.id == 2 ? null :
                                                                    <option selected={this.state.methods_of_purchase_id == item.id ? true : false} value={item.id} >{item.name}</option>
                                                                }
                                                            </>
                                                        )}
                                                    </>
                                                    : null}
                                            </select>
                                            {this.state.errorMessage.methods_of_purchase_id && <div className='errorMessage'> {this.state.errorMessage.methods_of_purchase_id[0]} </div>}
                                        </div>

                                        {this.state.methods_of_purchase_id && this.state.methods_of_purchase_id != 0 ?

                                            <div className="row">

                                                {this.state.methods_of_purchase_id && this.state.methods_of_purchase_id != 2 ?
                                                    <div className="col mb-3">
                                                        <label className="form-label">{strings["Interested branches"]} <span>*</span></label>
                                                        <select className="form-select" defaultValue={this.state.branch_id} onChange={(e) => this.changeBranchs(e.target.value)}>
                                                            <option value={0}>{strings["Choose a branch"]}</option>
                                                            {this.state.dataStep2 && this.state.dataStep2.branch_list ?
                                                                <>
                                                                    {this.state.dataStep2.branch_list.map((item, i) =>
                                                                        <option selected={this.state.branch_id == item.id ? true : false} value={item.id} >{item.name}</option>
                                                                    )}
                                                                </>
                                                                : null}
                                                        </select>
                                                        {this.state.errorMessage.branch_id && <div className='errorMessage'> {this.state.errorMessage.branch_id[0]} </div>}
                                                    </div>
                                                    : null}

                                                {this.state.branch_id && this.state.branch_id != 0 ?
                                                    <>
                                                        <div className="col mb-3">
                                                            <label className="form-label">{strings["Booking Date"]}</label>
                                                            <DatePicker
                                                                className="form-select"
                                                                selected={this.state.branch_booking_date}
                                                                minDate={moment().add(1, "days")._d}
                                                                onChange={(date) => this.changeDate(date)}
                                                            />
                                                            {this.state.errorMessage.branch_booking_date && <div className='errorMessage'> {this.state.errorMessage.branch_booking_date[0]} </div>}
                                                        </div>
                                                        <div className="col mb-3">
                                                            <label className="form-label">{strings["Booking Time"]} <span>*</span></label>
                                                            <select className="form-select" defaultValue={this.state.branch_booking_time} onChange={(e) => this.setState({ branch_booking_time: e.target.value })}>
                                                                <option value={0}>{strings["Select a time"]}</option>
                                                                {this.state.available_time_slot_list && this.state.available_time_slot_list.length != 0 ?
                                                                    <>
                                                                        {this.state.available_time_slot_list.map((item, i) =>
                                                                            <option selected={this.state.branch_booking_time == item ? true : false} value={item} >{item}</option>
                                                                        )}
                                                                    </>
                                                                    : null}
                                                            </select>
                                                            {this.state.errorMessage.branch_booking_time && <div className='errorMessage'> {this.state.errorMessage.branch_booking_time[0]} </div>}
                                                        </div>
                                                    </>
                                                    : null}
                                            </div>
                                            : null}

                                        {this.state.methods_of_purchase_id == '2' ?
                                            <>
                                                <div className="row">
                                                    <div className="col mb-3">
                                                        <span className='errorMessage'> *</span>
                                                        {strings["Home Purchase is to view and choose the choices and colour of phones."]}
                                                        <br />
                                                        {strings["It is not compulsory to purchase the device."]}
                                                    </div>
                                                </div>
                                            </>
                                            : null}

                                        {this.state.register_for == '1' ?
                                            <div className="row">
                                                <div className="col mb-3">
                                                    {strings["Disclaimer: price of the amount varies with condition of of the devices and evaluation from the risk managing team"]}
                                                </div>
                                            </div>
                                            : null}

                                    </div>
                                    <div className="col-12 text-center">
                                        <div className="row gx-4 justify-content-center">
                                            <div className="col col-md-4">
                                                <button onClick={() => this.setState({ step: 1 })} href="productstep1.html" type="submit" className="btn btn-gray">
                                                    <i className="fa fa-angle-left"></i> {strings["Back"]}
                                                </button>
                                            </div>
                                            <div className="col col-md-4">
                                                <button onClick={() => this.checkStepValidation(2)} href="productstep3.html" type="submit" className="btn btn-orange">
                                                    {strings["Next"]} <i className="fa fa-angle-right"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                : null}
                            {(this.state.step == 3) ?
                                <>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col mb-3">
                                                <label className="form-label">{strings["ID card number"]} <span>*</span></label>
                                                <input placeholder={strings["Fill in the ID card number"]} onChange={(e) => this.setState({ id_card_number: e.target.value })} value={this.state.id_card_number} name="id_card_number" type="text" className="form-control" />
                                                {this.state.errorMessage.id_card_number && <div className='errorMessage'> {this.state.errorMessage.id_card_number[0]} </div>}
                                            </div>
                                            {/* <div className="col mb-3">
                                                <label className="form-label">{strings["Phone number"]} <span>*</span></label>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <input style={{ width: 50, marginRight: 10 }} onChange={(e) => this.setState({ dial_code: e.target.value })} value={this.state.dial_code} name="mobile" type="text" className="form-control" placeholder='+66' readOnly />
                                                    <input placeholder={strings["Enter your phone number"]} onChange={(e) => this.checkOnlyNumber(e.target.value, "mobile_personal")} value={this.state.mobile_personal} name="mobile_personal" type="text" className="form-control" />
                                                </div>
                                                {this.state.errorMessage.mobile_personal && <div className='errorMessage'> {this.state.errorMessage.mobile_personal[0]} </div>}
                                            </div> */}
                                        </div>
                                        <div className="row">
                                            <div className="col mb-3">
                                                <label className="form-label">{strings["Prefix"]} <span>*</span></label>
                                                <select className="form-select" defaultValue={this.state.prefix} onChange={(e) => this.setState({ prefix: e.target.value })}>
                                                    <option value={0}>{strings["Choose a prefix"]}</option>
                                                    {this.state.dataStep3 && this.state.dataStep3.name_prefix_list ?
                                                        <>
                                                            {this.state.dataStep3.name_prefix_list.map((item, i) =>
                                                                <option selected={this.state.prefix == item.id ? true : false} value={item.id} >{item.name}</option>
                                                            )}
                                                        </>
                                                        : null}
                                                </select>
                                                {this.state.errorMessage.prefix && <div className='errorMessage'> {this.state.errorMessage.prefix[0]} </div>}
                                            </div>
                                            <div className="col mb-3">
                                                <label className="form-label">{strings["Name (Thai)"]} <span>*</span></label>
                                                <input placeholder={strings["Fill in name"]} onChange={(e) => this.setState({ first_name_personal: e.target.value })} value={this.state.first_name_personal} name="first_name_personal" type="text" className="form-control" />
                                                {this.state.errorMessage.first_name_personal && <div className='errorMessage'> {this.state.errorMessage.first_name_personal[0]} </div>}
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">{strings["Last name (Thai language)"]} <span>*</span></label>
                                                <input placeholder={strings["Enter last name"]} onChange={(e) => this.setState({ last_name_personal: e.target.value })} value={this.state.last_name_personal} name="last_name_personal" type="text" className="form-control" />
                                                {this.state.errorMessage.last_name_personal && <div className='errorMessage'> {this.state.errorMessage.last_name_personal[0]} </div>}
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">{strings["Current address"]}</label>
                                            <input placeholder={strings["House number/village/building"]} onChange={(e) => this.setState({ current_address: e.target.value })} value={this.state.current_address} name="current_address" type="text" className="form-control" />
                                            {this.state.errorMessage.current_address && <div className='errorMessage'> {this.state.errorMessage.current_address[0]} </div>}
                                        </div>
                                        <div className="row">
                                            <div className="col mb-3">
                                                <label className="form-label">{strings["Zip code"]} <span>*</span></label>
                                                <input placeholder={strings["Enter postal code"]} onChange={(e) => this.setState({ zip_code: e.target.value })} value={this.state.zip_code} name="zip_code" type="text" className="form-control" />
                                                {this.state.errorMessage.zip_code && <div className='errorMessage'> {this.state.errorMessage.zip_code[0]} </div>}
                                            </div>
                                            <div className="col mb-3">
                                                <label className="form-label">{strings["Province"]} <span>*</span></label>
                                                <select className="form-select" defaultValue={this.state.province} onChange={(e) => this.onchangeProvince(e)}>
                                                    <option value={""}>{strings["Select province"]}</option>
                                                    {this.state.provinces ?
                                                        <>
                                                            {this.state.provinces.map((item, i) =>
                                                                <option data-provinceCode={item.provinceCode} selected={(this.state.province == ((this.state.lang == "en") ? item.provinceNameEn : item.provinceNameTh)) ? true : false} value={(this.state.lang == "en") ? item.provinceNameEn : item.provinceNameTh} >{(this.state.lang == "en") ? item.provinceNameEn : item.provinceNameTh}</option>
                                                            )}
                                                        </>
                                                        : null}
                                                </select>
                                                {this.state.errorMessage.province && <div className='errorMessage'> {this.state.errorMessage.province[0]} </div>}
                                            </div>
                                            <div className="col mb-3">
                                                <label className="form-label">{strings["District"]} <span>*</span></label>
                                                <select className="form-select" defaultValue={this.state.district} onChange={(e) => this.onchangeDistrict(e)}>
                                                    <option value={""}>{strings["Choose A District"]}</option>
                                                    {this.state.districts ?
                                                        <>
                                                            {this.state.districts.map((item, i) =>
                                                                <option data-districtCode={item.districtCode} selected={(this.state.district == ((this.state.lang == "en") ? item.districtNameEn : item.districtNameTh)) ? true : false} value={(this.state.lang == "en") ? item.districtNameEn : item.districtNameTh} >{(this.state.lang == "en") ? item.districtNameEn : item.districtNameTh}</option>
                                                            )}
                                                        </>
                                                        : null}
                                                </select>
                                                {this.state.errorMessage.district && <div className='errorMessage'> {this.state.errorMessage.district[0]} </div>}
                                            </div>
                                            <div className="col mb-3">
                                                <label className="form-label">{strings["Subdistrict"]} <span>*</span></label>
                                                <select className="form-select" defaultValue={this.state.subdistrict} onChange={(e) => this.onchangeSubDistrict(e)}>
                                                    <option value={""}>{strings["Choose A Subdistrict"]}</option>
                                                    {this.state.subdistricts ?
                                                        <>
                                                            {this.state.subdistricts.map((item, i) =>
                                                                <option data-subdistrictCode={item.subdistrictCode} selected={(this.state.subdistrict == ((this.state.lang == "en") ? item.subdistrictNameEn : item.subdistrictNameTh)) ? true : false} value={(this.state.lang == "en") ? item.subdistrictNameEn : item.subdistrictNameTh} >{(this.state.lang == "en") ? item.subdistrictNameEn : item.subdistrictNameTh}</option>
                                                            )}
                                                        </>
                                                        : null}
                                                </select>
                                                {this.state.errorMessage.subdistrict && <div className='errorMessage'> {this.state.errorMessage.subdistrict[0]} </div>}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col mb-3">
                                                <label className="form-label">{strings["Facebook"]} <span>{strings["*Incorrect entries will not be considered."]}</span></label>
                                                <input placeholder={strings["Fill out facebook"]} onChange={(e) => this.setState({ facebook: e.target.value })} value={this.state.facebook} name="facebook" type="text" className="form-control" />
                                                {this.state.errorMessage.facebook && <div className='errorMessage'> {this.state.errorMessage.facebook[0]} </div>}
                                            </div>
                                            {/* <div className="col mb-3">
                                                <label className="form-label">{strings["ID Line"]} <span>*</span></label>
                                                <input placeholder={strings["Enter ID Line"]} onChange={(e) => this.setState({ id_line: e.target.value })} value={this.state.id_line} name="id_line" type="text" className="form-control" />
                                                {this.state.errorMessage.id_line && <div className='errorMessage'> {this.state.errorMessage.id_line[0]} </div>}
                                            </div> */}
                                        </div>
                                        <div className="row">
                                            {/* <div className="col mb-3">
                                                <label className="form-label">{strings["Number of people to take care of expenses"]} <span>*</span></label>
                                                <select className="form-select" defaultValue={this.state.number_of_people} onChange={(e) => this.setState({ number_of_people: e.target.value })}>
                                                    <option value={0}>{strings["Choose Number"]}</option>
                                                    {this.state.dataStep3 && this.state.dataStep3.number_of_people_list ?
                                                        <>
                                                            {this.state.dataStep3.number_of_people_list.map((item, i) =>
                                                                <option selected={this.state.number_of_people == item.id ? true : false} value={item.id} >{item.name}</option>
                                                            )}
                                                        </>
                                                        : null}
                                                </select>
                                                {this.state.errorMessage.number_of_people && <div className='errorMessage'> {this.state.errorMessage.number_of_people[0]} </div>}
                                            </div> */}
                                            {/* <div className="col mb-4">
                                                <label className="form-label">{strings["What mobile phone system do you use?"]} <span>*</span></label>
                                                <select className="form-select" defaultValue={this.state.sim_plan} onChange={(e) => this.setState({ sim_plan: e.target.value })}>
                                                    <option value={0}>{strings["Choose a mobile phone system"]}</option>
                                                    {this.state.dataStep3 && this.state.dataStep3.sim_plan_list ?
                                                        <>
                                                            {this.state.dataStep3.sim_plan_list.map((item, i) =>
                                                                <option selected={this.state.sim_plan == item.id ? true : false} value={item.id} >{item.name}</option>
                                                            )}
                                                        </>
                                                        : null}
                                                </select>
                                                {this.state.errorMessage.sim_plan && <div className='errorMessage'> {this.state.errorMessage.sim_plan[0]} </div>}
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="col-12 text-center">
                                        <div className="row gx-4 justify-content-center">
                                            <div className="col col-md-4">
                                                <button onClick={() => this.setState({ step: 2 })} href="productstep1.html" type="submit" className="btn btn-gray">
                                                    <i className="fa fa-angle-left"></i> {strings["Back"]}
                                                </button>
                                            </div>
                                            <div className="col col-md-4">
                                                <button onClick={() => this.checkStepValidation(3)} href="productstep3.html" type="submit" className="btn btn-orange">
                                                    {strings["Next"]} <i className="fa fa-angle-right"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                : null}
                            {(this.state.step == 4) ?
                                <>
                                    <div className="col-12">
                                        <div className="col mb-3">
                                            <label className="form-label">{strings["Occupation"]} <span>*</span></label>
                                            <select className="form-select" defaultValue={this.state.occupation_type} onChange={(e) => this.setState({ occupation_type: e.target.value })}>
                                                {this.state.dataStep4 && this.state.dataStep4.occupation_type_list ?
                                                    <>
                                                        {this.state.dataStep4.occupation_type_list.map((item, i) =>
                                                            <option selected={this.state.occupation_type == item.id ? true : false} value={item.id} >{item.name}</option>
                                                        )}
                                                    </>
                                                    : null}
                                            </select>
                                            {this.state.errorMessage.occupation_type && <div className='errorMessage'> {this.state.errorMessage.occupation_type[0]} </div>}
                                        </div>

                                        {this.state.occupation_type == 1 ?
                                            <>
                                                <div className="row">
                                                    <div className="col mb-3">
                                                        <label className="form-label">{strings["Career"]} <span>*</span></label>
                                                        <select className="form-select" defaultValue={this.state.career} onChange={(e) => this.setState({ career: e.target.value })}>
                                                            <option value={0}>{strings["Choose a career"]}</option>
                                                            {this.state.dataStep4 && this.state.dataStep4.career_list ?
                                                                <>
                                                                    {this.state.dataStep4.career_list.map((item, i) =>
                                                                        <option selected={this.state.career == item.id ? true : false} value={item.id} >{item.name}</option>
                                                                    )}
                                                                </>
                                                                : null}
                                                        </select>
                                                        {this.state.errorMessage.career && <div className='errorMessage'> {this.state.errorMessage.career[0]} </div>}
                                                    </div>
                                                    <div className="col mb-3">
                                                        <label className="form-label">{strings["Nature of Business"]} <span>*</span></label>
                                                        <select className="form-select" defaultValue={this.state.nature_of_business} onChange={(e) => this.setState({ nature_of_business: e.target.value })}>
                                                            <option value={0}>{strings["Choose a business"]}</option>
                                                            {this.state.dataStep4 && this.state.dataStep4.nature_of_business_list ?
                                                                <>
                                                                    {this.state.dataStep4.nature_of_business_list.map((item, i) =>
                                                                        <option selected={this.state.nature_of_business == item.id ? true : false} value={item.id} >{item.name}</option>
                                                                    )}
                                                                </>
                                                                : null}
                                                        </select>
                                                        {this.state.errorMessage.nature_of_business && <div className='errorMessage'> {this.state.errorMessage.nature_of_business[0]} </div>}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col mb-3">
                                                        <label className="form-label">{strings["Company name"]} <span>*</span></label>
                                                        <input placeholder={strings["Fill in company name"]} onChange={(e) => this.setState({ company_name: e.target.value })} value={this.state.company_name} name="company_name" type="text" className="form-control" />
                                                        {this.state.errorMessage.company_name && <div className='errorMessage'> {this.state.errorMessage.company_name[0]} </div>}

                                                    </div>
                                                    <div className="col mb-3">
                                                        <label className="form-label">{strings["Place of work"]} <span>*</span></label>
                                                        <input placeholder={strings["Fill out the place of work."]} onChange={(e) => this.setState({ place_of_work: e.target.value })} value={this.state.place_of_work} name="place_of_work" type="text" className="form-control" />
                                                        {this.state.errorMessage.place_of_work && <div className='errorMessage'> {this.state.errorMessage.place_of_work[0]} </div>}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col mb-3">
                                                        <label className="form-label">{strings["Job title"]} <span>*</span></label>
                                                        <input placeholder={strings["Fill in job title"]} onChange={(e) => this.setState({ job_title: e.target.value })} value={this.state.job_title} name="job_title" type="text" className="form-control" />
                                                        {this.state.errorMessage.job_title && <div className='errorMessage'> {this.state.errorMessage.job_title[0]} </div>}
                                                    </div>
                                                    <div className="col mb-3">
                                                        <label className="form-label">{strings["Working age"]} <span>*</span></label>
                                                        <select className="form-select" defaultValue={this.state.working_age} onChange={(e) => this.setState({ working_age: e.target.value })}>
                                                            <option value={0}>{strings["Choose the age of work"]}</option>
                                                            {this.state.dataStep4 && this.state.dataStep4.working_age_list ?
                                                                <>
                                                                    {this.state.dataStep4.working_age_list.map((item, i) =>
                                                                        <option selected={this.state.working_age == item.id ? true : false} value={item.id} >{item.name}</option>
                                                                    )}
                                                                </>
                                                                : null}
                                                        </select>
                                                        {this.state.errorMessage.working_age && <div className='errorMessage'> {this.state.errorMessage.working_age[0]} </div>}
                                                    </div>
                                                    <div className="col mb-3">
                                                        <label className="form-label">{strings["Monthly income"]} <span>*</span></label>
                                                        <input placeholder={strings["Enter your monthly income"]} onChange={(e) => this.setState({ monthly_income: e.target.value })} value={this.state.monthly_income} name="monthly_income" type="text" className="form-control" />
                                                        {this.state.errorMessage.monthly_income && <div className='errorMessage'> {this.state.errorMessage.monthly_income[0]} </div>}
                                                    </div>
                                                    {(this.state.register_for != 1) ?
                                                        <div className="col mb-3">
                                                            <label className="form-label">{strings["Work number"]} <span>*</span></label>
                                                            <input placeholder={strings["Enter your work number"]} onChange={(e) => this.setState({ work_mobile: e.target.value })} value={this.state.work_mobile} name="work_mobile" type="text" className="form-control" />
                                                            {this.state.errorMessage.work_mobile && <div className='errorMessage'> {this.state.errorMessage.work_mobile[0]} </div>}
                                                        </div>
                                                        : null}
                                                </div>

                                                {this.state.register_for == 2 ?
                                                    <div className="row">
                                                        <div className="col mb-3">
                                                            {strings["Disclaimer: We may need a payslip for proof of employment."]}
                                                        </div>
                                                    </div>
                                                    : null}

                                                {(this.state.register_for != 1) ?
                                                    <>
                                                        <div className="col mb-3">
                                                            <label className="form-label">{strings["Occupation"]} <span>*</span></label>
                                                            <div className="row ps-3">
                                                                <div className="col-auto form-check">
                                                                    <input className="form-check-input" type="radio" name="income_document_type" id="income_document_type_1" onClick={() => this.setState({ income_document_type: 1 })} defaultChecked={(this.state.income_document_type == 1) ? true : false} />
                                                                    <label className="form-check-label" htmlFor="income_document_type_1">{strings["Latest salary slip"]}</label>
                                                                </div>
                                                                <div className="col form-check">
                                                                    <input className="form-check-input" type="radio" name="income_document_type" id="income_document_type_2" onClick={() => this.setState({ income_document_type: 2 })} defaultChecked={(this.state.income_document_type == 2) ? true : false} />
                                                                    <label className="form-check-label" htmlFor="income_document_type_2"> {strings["Statement 3 months"]} <span>({strings["*.pdf file only"]})</span></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">{strings["Attach a file"]} <span>*</span></label>
                                                            <div className="col col-md-3 d-grid">
                                                                <input name='income_document'
                                                                    onChange={(event) => {
                                                                        this.setState({ income_document: event.target.files[0] });
                                                                    }}
                                                                    className="form-control text-center" type="file" placeholder="Upload" />
                                                                {this.state.errorMessage.income_document && <div className='errorMessage'> {this.state.errorMessage.income_document[0]} </div>}
                                                            </div>
                                                        </div>
                                                    </>
                                                    : null}
                                            </>
                                            : null}

                                        {this.state.occupation_type == 2 ?
                                            <>
                                                <div className="row">
                                                    <div className="col mb-3">
                                                        <label className="form-label">{strings["University"]} <span>*</span></label>
                                                        <input placeholder={strings["Fill out the university"]} onChange={(e) => this.setState({ university: e.target.value })} value={this.state.university} name="university" type="text" className="form-control" />
                                                        {this.state.errorMessage.university && <div className='errorMessage'> {this.state.errorMessage.university[0]} </div>}
                                                    </div>
                                                    <div className="col mb-3">
                                                        <label className="form-label">{strings["Faculty"]} <span>*</span></label>
                                                        <input placeholder={strings["Fill out the faculty"]} onChange={(e) => this.setState({ faculty: e.target.value })} value={this.state.faculty} name="faculty" type="text" className="form-control" />
                                                        {this.state.errorMessage.faculty && <div className='errorMessage'> {this.state.errorMessage.faculty[0]} </div>}
                                                    </div>
                                                    <div className="col mb-3">
                                                        <label className="form-label">{strings["Major"]} <span>*</span></label>
                                                        <input placeholder={strings["Fill out field of study"]} onChange={(e) => this.setState({ major: e.target.value })} value={this.state.major} name="major" type="text" className="form-control" />
                                                        {this.state.errorMessage.major && <div className='errorMessage'> {this.state.errorMessage.major[0]} </div>}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col mb-3">
                                                        <label className="form-label">{strings["Education level"]} <span>*</span></label>
                                                        <select className="form-select" defaultValue={this.state.education_level} onChange={(e) => this.setState({ education_level: e.target.value })}>
                                                            <option value={0}>{strings["Select level of education"]}</option>
                                                            {this.state.dataStep4 && this.state.dataStep4.education_level_list ?
                                                                <>
                                                                    {this.state.dataStep4.education_level_list.map((item, i) =>
                                                                        <option selected={this.state.education_level == item.id ? true : false} value={item.id} >{item.name}</option>
                                                                    )}
                                                                </>
                                                                : null}
                                                        </select>
                                                        {this.state.errorMessage.education_level && <div className='errorMessage'> {this.state.errorMessage.education_level[0]} </div>}
                                                    </div>
                                                    <div className="col mb-3">
                                                        <label className="form-label">{strings["Year"]} <span>*</span></label>
                                                        <input placeholder={strings["Fill in year"]} onChange={(e) => this.setState({ year: e.target.value })} value={this.state.year} name="year" type="text" className="form-control" />
                                                        {this.state.errorMessage.year && <div className='errorMessage'> {this.state.errorMessage.year[0]} </div>}
                                                    </div>
                                                    <div className="col mb-3">
                                                        <label className="form-label">{strings["School year attended"]} <span>*</span></label>
                                                        <input placeholder={strings["Enter the academic year attended."]} onChange={(e) => this.setState({ school_year_attended: e.target.value })} value={this.state.school_year_attended} name="school_year_attended" type="text" className="form-control" />
                                                        {this.state.errorMessage.school_year_attended && <div className='errorMessage'> {this.state.errorMessage.school_year_attended[0]} </div>}
                                                    </div>
                                                    <div className="col mb-3">
                                                        <label className="form-label">{strings["Average income"]} <span>*</span></label>
                                                        <input placeholder={strings["Fill in average income"]} onChange={(e) => this.setState({ average_income: e.target.value })} value={this.state.average_income} name="average_income" type="text" className="form-control" />
                                                        {this.state.errorMessage.average_income && <div className='errorMessage'> {this.state.errorMessage.average_income[0]} </div>}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label">{strings["Student card/student record"]} <span>*</span></label>
                                                        <input className="form-control text-center"
                                                            onChange={(event) => {
                                                                this.setState({ student_income_document: event.target.files[0] });
                                                            }}
                                                            name='student_income_document' type="file" placeholder="Upload" />
                                                        {this.state.errorMessage.student_income_document && <div className='errorMessage'> {this.state.errorMessage.student_income_document[0]} </div>}
                                                    </div>
                                                    <div className="col-md-3 mb-3">
                                                        <label className="form-label">{strings["File access code"]}</label>
                                                        <input placeholder="Enter file password" onChange={(e) => this.setState({ student_card: e.target.value })} value={this.state.student_card} name="student_card" type="text" className="form-control" />
                                                        {this.state.errorMessage.student_card && <div className='errorMessage'> {this.state.errorMessage.student_card[0]} </div>}
                                                    </div>
                                                </div>
                                                {/* <div className="col mb-4">
                                                    <label className="form-label">{strings["Income documents"]} <span>*</span></label>
                                                    <div className="row ps-3">
                                                        <div className="col-auto form-check">
                                                            <input className="form-check-input" type="radio" name="student_income_document_type" id="student_income_document_type_1" onClick={() => this.setState({ student_income_document_type: 1 })} defaultChecked={(this.state.student_income_document_type == 1) ? true : false} />
                                                            <label className="form-check-label" htmlFor="student_income_document_type_1">{strings["Do not have"]} </label>
                                                        </div>
                                                        <div className="col-auto form-check">
                                                            <input className="form-check-input" type="radio" name="student_income_document_type" id="student_income_document_type_2" onClick={() => this.setState({ student_income_document_type: 2 })} defaultChecked={(this.state.student_income_document_type == 2) ? true : false} />
                                                            <label className="form-check-label" htmlFor="student_income_document_type_2">{strings["Latest salary slip"]}</label>
                                                        </div>
                                                        <div className="col-auto form-check">
                                                            <input className="form-check-input" type="radio" name="student_income_document_type" id="student_income_document_type_3" onClick={() => this.setState({ student_income_document_type: 3 })} defaultChecked={(this.state.student_income_document_type == 3) ? true : false} />
                                                            <label className="form-check-label" htmlFor="student_income_document_type_3"> {strings["Statement 3 months"]} <span>({strings["*.pdf file only"]})</span> </label>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </>
                                            : null}
                                    </div>
                                    <div className="col-12 text-center">
                                        <div className="row gx-4 justify-content-center">
                                            <div className="col col-md-4">
                                                <button onClick={() => this.setState({ step: 3 })} href="productstep2.html" type="submit" className="btn btn-gray">
                                                    <i className="fa fa-angle-left"></i> {strings["Back"]}
                                                </button>
                                            </div>
                                            <div className="col col-md-4">
                                                <button onClick={() => this.checkStepValidation(4)} type="submit" className="btn btn-orange">
                                                    {strings["Next"]} <i className="fa fa-angle-right"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                : null}
                            {(this.state.step == 5) ?
                                <>
                                    <div className="col-12">
                                        <div className="row">
                                            {/* <label className="form-label">{strings["First contact person"]} <span>*</span></label> */}
                                            <div className="col mb-3">
                                                <label className="form-label">{strings["Relationship"]} <span>*</span></label>
                                                <select className="form-select" defaultValue={this.state.relationship1} onChange={(e) => this.setState({ relationship1: e.target.value })}>
                                                    <option defaultValue={""}>{strings["Choose relationship"]}</option>
                                                    {this.state.dataStep5 && this.state.dataStep5.relationship_list ?
                                                        <>
                                                            {this.state.dataStep5.relationship_list.map((item, i) =>
                                                                <option selected={this.state.relationship1 == item.id ? true : false} value={item.id} >{item.name}</option>
                                                            )}
                                                        </>
                                                        : null}
                                                </select>
                                                {this.state.errorMessage.relationship1 && <div className='errorMessage'> {this.state.errorMessage.relationship1[0]} </div>}
                                            </div>
                                            <div className="col mb-3">
                                                <label className="form-label">{strings["Phone Number"]} <span>*</span></label>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <input style={{ width: 50, marginRight: 10 }} onChange={(e) => this.setState({ dial_code: e.target.value })} value={this.state.dial_code} name="mobile" type="text" className="form-control" placeholder='+66' readOnly />
                                                    <input placeholder={strings["Enter your phone number"]} onChange={(e) => this.checkOnlyNumber(e.target.value, "mobile1")} value={this.state.mobile1} name="mobile1" type="text" className="form-control" />
                                                </div>
                                                {this.state.errorMessage.mobile1 && <div className='errorMessage'> {this.state.errorMessage.mobile1[0]} </div>}
                                            </div>
                                            <div className="col mb-3">
                                                <label className="form-label">{strings["Name"]} <span>*</span></label>
                                                <input placeholder={strings["Fill in name"]} onChange={(e) => this.setState({ first_name1: e.target.value })} value={this.state.first_name1} name="first_name1" type="text" className="form-control" />
                                                {this.state.errorMessage.first_name1 && <div className='errorMessage'> {this.state.errorMessage.first_name1[0]} </div>}
                                            </div>
                                            <div className="col mb-3">
                                                <label className="form-label">{strings["Last name"]} <span>*</span></label>
                                                <input placeholder={strings["Enter last name"]} onChange={(e) => this.setState({ last_name1: e.target.value })} value={this.state.last_name1} name="last_name1" type="text" className="form-control" />
                                                {this.state.errorMessage.last_name1 && <div className='errorMessage'> {this.state.errorMessage.last_name1[0]} </div>}
                                            </div>
                                        </div>
                                        {/* <div className="row">
                                            <label className="form-label">{strings["Secondary contact person"]} <span>*</span></label>
                                            <div className="col mb-3">
                                                <label className="form-label">{strings["Relationship"]} <span>*</span></label>
                                                <select className="form-select" defaultValue={this.state.relationship2} onChange={(e) => this.setState({ relationship2: e.target.value })}>
                                                    <option defaultValue={""}>{strings["Choose relationship"]}</option>
                                                    {this.state.dataStep5 && this.state.dataStep5.relationship_list ?
                                                        <>
                                                            {this.state.dataStep5.relationship_list.map((item, i) =>
                                                                <option selected={this.state.relationship2 == item.id ? true : false} value={item.id} >{item.name}</option>
                                                            )}
                                                        </>
                                                        : null}
                                                </select>
                                                {this.state.errorMessage.relationship2 && <div className='errorMessage'> {this.state.errorMessage.relationship2[0]} </div>}
                                            </div>
                                            <div className="col mb-3">
                                                <label className="form-label">{strings["Phone Number"]} <span>*</span></label>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <input style={{ width: 50, marginRight: 10 }} onChange={(e) => this.setState({ dial_code: e.target.value })} value={this.state.dial_code} name="mobile" type="text" className="form-control" placeholder='+66' readOnly />
                                                    <input placeholder={strings["Enter your phone number"]} onChange={(e) => this.checkOnlyNumber(e.target.value, "mobile2")} value={this.state.mobile2} name="mobile2" type="text" className="form-control" />
                                                </div>
                                                {this.state.errorMessage.mobile2 && <div className='errorMessage'> {this.state.errorMessage.mobile2[0]} </div>}
                                            </div>
                                            <div className="col mb-3">
                                                <label className="form-label">{strings["Name"]} <span>*</span></label>
                                                <input placeholder={strings["Fill in name"]} onChange={(e) => this.setState({ first_name2: e.target.value })} value={this.state.first_name2} name="first_name2" type="text" className="form-control" />
                                                {this.state.errorMessage.first_name2 && <div className='errorMessage'> {this.state.errorMessage.first_name2[0]} </div>}
                                            </div>
                                            <div className="col mb-3">
                                                <label className="form-label">{strings["Last name"]} <span>*</span></label>
                                                <input placeholder={strings["Enter last name"]} onChange={(e) => this.setState({ last_name2: e.target.value })} value={this.state.last_name2} name="last_name2" type="text" className="form-control" />
                                                {this.state.errorMessage.last_name2 && <div className='errorMessage'> {this.state.errorMessage.last_name2[0]} </div>}
                                            </div>
                                        </div> */}
                                        <div className="col mb-3">
                                            <label className="form-label">{strings["You know Device Warehouse from"]} <span>*</span></label>
                                            <div className="col-md-12">
                                                <div className="row ps-3">
                                                    {this.state.dataStep5 && this.state.dataStep5.referred_from_list ?
                                                        <>
                                                            {this.state.dataStep5.referred_from_list.map((item, i) =>
                                                                <div className="col-3 form-check">
                                                                    <input className="form-check-input" type="radio" name="referred_from" id={"referred_from_" + item.id} onClick={() => this.setState({ referred_from: item.id })} defaultChecked={(this.state.referred_from == item.id) ? true : false} />
                                                                    <label className="form-check-label" for={"referred_from_" + item.id}>{item.name}</label>
                                                                </div>
                                                            )}
                                                        </>
                                                        : null}
                                                </div>
                                                {this.state.errorMessage.referred_from && <div className='errorMessage'> {this.state.errorMessage.referred_from[0]} </div>}
                                            </div>
                                        </div>
                                        <div className="col mb-3">
                                            <label className="form-label">{strings["More"]}</label>
                                            <textarea onChange={(e) => this.setState({ referred_from_manually: e.target.value })} value={this.state.referred_from_manually} name="referred_from_manually" id="exampleFormControlTextarea1" rows="3" className="form-control"></textarea>
                                            {this.state.errorMessage.referred_from_manually && <div className='errorMessage'> {this.state.errorMessage.referred_from_manually[0]} </div>}
                                        </div>
                                        <div className="col-auto mb-4 form-check">
                                            <input className="form-check-input" onClick={() => this.setState({ accept_tc: !this.state.accept_tc })} type="radio" name="accept_tc" id="accept_tc" />
                                            <label className="form-check-label" for="accept_tc">
                                                {strings["Accept the terms of the agreement"]}
                                                <a href="#" className="link">{strings["Privacy policy"]}</a>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="col-12 text-center">
                                        <div className="row gx-4 justify-content-center">
                                            <div className="col col-md-4">
                                                <button onClick={() => this.setState({ step: 4 })} type="submit" className="btn btn-gray"><i className="fa fa-angle-left"></i> {strings["Back"]}</button>
                                            </div>
                                            <div className="col col-md-4">
                                                <button onClick={() => this.checkStepValidation(5)} type="submit" className="btn btn-orange">
                                                    {strings["Next"]} <i className="fa fa-angle-right"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                : null}
                        </div>
                    </div>
                </div >

                <Footer />
            </>
        );
    }
}

export default ProductRegisterScreen;